define('ember-leaflet/mixins/popup', ['exports', 'ember', 'ember-leaflet/templates/popup'], function (exports, _ember, _emberLeafletTemplatesPopup) {
  'use strict';

  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var Mixin = _ember['default'].Mixin;
  var schedule = _ember['default'].run.schedule;

  exports['default'] = Mixin.create({

    // we need a "tagfull" here to have `this.element`
    tagName: 'div',

    layout: _emberLeafletTemplatesPopup['default'],
    popupOpen: false,

    closePopup: function closePopup() {
      this._popup._close();
    },

    /*
     * Evil hack by @rwjblue.
     * `hasBlock` isn't available in js land.
     * More info: https://github.com/miguelcobain/rfcs/blob/js-has-block/text/0000-js-has-block.md#alternatives
     */
    setHasBlock: computed(function () {
      this.set('hasBlock', true);
    }),

    // creates a document fragment that will hold the DOM
    destinationElement: computed(function () {
      return document.createElement('div');
    }),

    popupOpenDidChange: observer('popupOpen', function () {
      if (this.get('popupOpen')) {
        if (!this._popup._isOpen) {
          this._layer.openPopup();
        }
      } else {
        if (this._popup._isOpen) {
          this._layer.closePopup();
        }
      }
    }),

    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this._firstNode = this.element.firstChild;
      this._lastNode = this.element.lastChild;
      this.appendToDestination();
    },

    appendToDestination: function appendToDestination() {
      var destinationElement = this.get('destinationElement');
      this.appendRange(destinationElement, this._firstNode, this._lastNode);
    },

    appendRange: function appendRange(destinationElement, firstNode, lastNode) {
      while (firstNode) {
        destinationElement.insertBefore(firstNode, null);
        firstNode = firstNode !== lastNode ? lastNode.parentNode.firstChild : null;
      }
    },

    didCreateLayer: function didCreateLayer() {
      this._super.apply(this, arguments);
      if (this.get('hasBlock')) {
        this._popup = this.L.popup({}, this._layer);
        this._popup.setContent(this.get('destinationElement'));
        this._layer.bindPopup(this._popup, this.get('popupOptions'));

        this._hijackPopup();

        this.popupOpenDidChange();
      }
    },

    _hijackPopup: function _hijackPopup() {
      var _this = this;

      var oldOnAdd = this._popup.onAdd;
      this._popup.onAdd = function (map) {
        _this.set('popupOpen', true);
        schedule('render', function () {
          oldOnAdd.call(_this._popup, map);
        });
      };

      var oldOnRemove = this._popup.onRemove;
      this._popup.onRemove = function (map) {
        oldOnRemove.call(_this._popup, map);
        _this.set('popupOpen', false);
      };
    },

    willDestroyLayer: function willDestroyLayer() {
      this._super.apply(this, arguments);
      if (this.get('hasBlock')) {
        this._layer.closePopup();
        this._layer.unbindPopup();
        delete this._popup;
        delete this._firstNode;
        delete this._lastNode;
      }
    }
  });
});