define('semantic-ui-ember/mixins/promise-resolver', ['exports', 'ember', 'semantic-ui-ember/utils/promise-tools'], function (exports, _ember, _semanticUiEmberUtilsPromiseTools) {
  'use strict';

  // Code referenced from https://github.com/fivetanley/ember-promise-helpers
  exports['default'] = _ember['default'].Mixin.create({
    resolvePromise: function resolvePromise(maybePromise, immediateResolve, delayedResolve) {
      var _this = this;

      if (!(0, _semanticUiEmberUtilsPromiseTools.isPromise)(maybePromise)) {
        return immediateResolve.call(this, maybePromise);
      }
      // If we've already fulfilled, just return to avoid returning null
      // Probably could tie into SetValue, something to think about later
      if ((0, _semanticUiEmberUtilsPromiseTools.isFulfilled)(maybePromise)) {
        return immediateResolve.call(this, (0, _semanticUiEmberUtilsPromiseTools.getPromiseContent)(maybePromise));
      }

      // If the type wasn't a PromiseProxy or RSVP, check if we resolved for .then
      if (maybePromise === this._currentPromise) {
        if (this._promiseWasSettled) {
          return immediateResolve.call(this, this._promiseValue);
        }
        return null; // Return we don't need to check the latest again
      }

      this.ensureLatestPromise(maybePromise, function (promise) {
        promise.then(function (value) {
          if (maybePromise === _this._currentPromise) {
            _this._promiseWasSettled = true;
            _this._promiseValue = value;
            // This will recompue the value and fire the _wasSettled check above
            return (delayedResolve || immediateResolve).call(_this, value);
          }
        })['catch'](function (error) {
          _ember['default'].Logger.error('Promise died in Semantic-UI-Ember promise-resolver');
          _ember['default'].Logger.error(error);
        });
      });
      return null;
    },

    ensureLatestPromise: function ensureLatestPromise(promise, callback) {
      // It's a new promise, reset
      this._promiseWasSettled = false;
      this._currentPromise = promise;

      callback.call(this, _ember['default'].RSVP.Promise.resolve(promise));
    }
  });
});