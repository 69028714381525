define('ui-dropzone/mixins/template-mixin', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var keys = Object.keys;
  var create = Object.create;
  // jshint ignore:line
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var on = _ember['default'].on;
  var typeOf = _ember['default'].typeOf;
  var debug = _ember['default'].debug;
  var isPresent = _ember['default'].isPresent;
  // jshint ignore:line
  var defineProperty = _ember['default'].defineProperty;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  var merge = _ember['default'].merge;
  // jshint ignore:line
  var a = _ember['default'].A; // jshint ignore:line

  exports['default'] = _ember['default'].Mixin.create({});
});