define('semantic-ui-ember/utils/promise-tools', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var isPromise = function isPromise(maybePromise) {
    if (maybePromise != null && typeof maybePromise.then === 'function') {
      return true;
    }
    return false;
  };

  // It's assumed if you call this method, it was previously checked that it is a promise
  var isFulfilled = function isFulfilled(promise) {
    if (_ember['default'].PromiseProxyMixin.detect(promise)) {
      if (promise.get('isFulfilled')) {
        return true;
      }

      return false;
    }

    if (promise instanceof _ember['default'].RSVP.Promise) {
      if (promise._state === 1) {
        // Fulfilled
        return true;
      }
      return false;
    }

    // Can't detect it if its not one of the two kinds above
    return false;
  };

  // It's assumed if you call this method, it was previously checked that it was a promise
  // and is fulfilled
  var getPromiseContent = function getPromiseContent(promise) {
    if (_ember['default'].PromiseProxyMixin.detect(promise)) {
      return promise.get('content');
    }

    if (promise instanceof _ember['default'].RSVP.Promise) {
      return promise._result;
    }

    // Only can get the content for one of the two above
    return null;
  };

  exports.isPromise = isPromise;
  exports.isFulfilled = isFulfilled;
  exports.getPromiseContent = getPromiseContent;
});