define('ember-metrics/metrics-adapters/base', ['exports', 'ember', 'ember-metrics/utils/can-use-dom'], function (exports, _ember, _emberMetricsUtilsCanUseDom) {
  /* jshint unused: false */
  'use strict';

  var emberObject = _ember['default'].Object;
  var aliasMethod = _ember['default'].aliasMethod;
  var assert = _ember['default'].assert;
  var guidFor = _ember['default'].guidFor;
  var typeOf = _ember['default'].typeOf;
  var set = _ember['default'].set;
  var K = _ember['default'].K;

  function makeToString(ret) {
    return function () {
      return ret;
    };
  }

  exports['default'] = emberObject.extend({
    init: function init() {
      assert('[ember-metrics] ' + this.toString() + ' must implement the init hook!');
    },

    willDestroy: function willDestroy() {
      assert('[ember-metrics] ' + this.toString() + ' must implement the willDestroy hook!');
    },

    toString: function toString() {
      var hasToStringExtension = typeOf(this.toStringExtension) === 'function';
      var extension = hasToStringExtension ? ':' + this.toStringExtension() : '';
      var ret = 'ember-metrics@metrics-adapter:' + extension + ':' + guidFor(this);

      this.toString = makeToString(ret);
      return ret;
    },

    metrics: null,
    config: null,
    identify: K,
    trackEvent: K,
    trackPage: K,
    alias: K
  });
});