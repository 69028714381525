define('ember-qrcode-shim/components/qr-code', ['exports', 'ember', 'qrcode'], function (exports, _ember, _qrcode) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({

    text: null,

    colorLight: '#ffffff',

    colorDark: '#000000',

    width: 128,

    height: 128,

    // L/M/Q/H
    correctLevel: 'Q',

    didInsertElement: function didInsertElement() {
      var text = this.get('text');
      var elementId = this.get('elementId');
      var correctLevel = this.get('correctLevel');
      var colorLight = this.get('colorLight');
      var colorDark = this.get('colorDark');
      var width = this.get('width');
      var height = this.get('height');

      var qrcode = new _qrcode['default'](elementId, {
        text: text,
        width: width,
        height: height,
        colorDark: colorDark,
        colorLight: colorLight,
        correctLevel: _qrcode['default'].CorrectLevel[correctLevel]
      });
      this.set('qrcode', qrcode);
    },

    willDestroyElement: function willDestroyElement() {
      this.get('qrcode').clear();
    },

    _recreateCode: _ember['default'].observer('text', function () {
      this.get('qrcode').makeCode(this.get('text'));
    })
  });
});