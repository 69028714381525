define('ember-leaflet/initializers/leaflet-assets', ['exports'], function (exports) {
  'use strict';

  exports.initialize = initialize;

  /* global L */

  function initialize() /* container, application */{
    L.Icon.Default.imagePath = 'assets/images';
  }

  exports['default'] = {
    name: 'leaflet-assets',
    initialize: initialize
  };
});