define('ember-leaflet/mixins/child', ['exports', 'ember', 'ember-leaflet/mixins/container'], function (exports, _ember, _emberLeafletMixinsContainer) {
  'use strict';

  var computed = _ember['default'].computed;
  var assert = _ember['default'].assert;

  exports['default'] = _ember['default'].Mixin.create({

    containerLayer: computed(function () {
      return this.nearestOfType(_emberLeafletMixinsContainer['default']);
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.registerWithParent();
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.unregisterWithParent();
    },

    registerWithParent: function registerWithParent() {
      var container = this.get('containerLayer');
      assert('Tried to use ' + this + ' outside the context of a container layer.', container);
      container.registerChild(this);
    },

    unregisterWithParent: function unregisterWithParent() {
      var container = this.get('containerLayer');
      if (container) {
        container.unregisterChild(this);
      }
    }

  });
});