define('ui-dropzone/mixins/xhr-intercept', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var keys = Object.keys;
  var create = Object.create;
  // jshint ignore:line
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var on = _ember['default'].on;
  var typeOf = _ember['default'].typeOf;
  var debug = _ember['default'].debug;
  var isPresent = _ember['default'].isPresent;
  // jshint ignore:line
  var defineProperty = _ember['default'].defineProperty;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  var RSVP = _ember['default'].RSVP;
  var merge = _ember['default'].merge;
  // jshint ignore:line
  var a = _ember['default'].A; // jshint ignore:line

  var _textTypes = ['text/*', 'application/xml', 'application/x-sh', 'application/x-script', 'image/svg+xml'];

  exports['default'] = _ember['default'].Mixin.create({
    /**
     * Replaces the XHR's send operation so that the stream can be
     * retrieved on the client side instead being sent to the server.
     * The function name is a little confusing (other than it replaces the "send"
     * from Dropzonejs) because really what it's doing is reading the file and
     * NOT sending to the server.
     */
    _sendIntercept: function _sendIntercept(file) {
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      return new RSVP.Promise(function (resolve, reject) {
        if (!options.readType) {
          (function () {
            var mime = file.type;
            var textType = a(_textTypes).any(function (type) {
              var re = new RegExp(type);
              return re.test(mime);
            });
            options.readType = textType ? 'readAsText' : 'readAsDataURL';
          })();
        }
        var reader = new window.FileReader();
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function () {
          reject(reader.result);
        };

        // run the reader
        reader[options.readType](file);
      });
    },

    /**
     * Will replace the Dropzone acceptance handler if user
     * sets the 'keepLocal' flag to true. Once a result is available
     * it will look for the `localSuccess` or `localFailure` handlers
     * if it does not find them it will simple use Dropzone's done()
     * callback directly
     */
    localAcceptHandler: function localAcceptHandler(file, done) {
      var _this = this;

      this._sendIntercept(file).then(function (result) {
        file.contents = result;
        if (typeOf(_this.localSuccess) === 'function') {
          _this.localSuccess(file, done);
        } else {
          done(); // empty done signals success
        }
      })['catch'](function (result) {
        if (typeOf(_this.localFailure) === 'function') {
          file.contents = result;
          _this.localFailure(file, done);
        } else {
          done('Failed to download file ' + file.name);
          console.warn(file);
        }
      });
    },

    _stubSubmitRequest: function _stubSubmitRequest() {
      this.dropzone.submitRequest = function (xhr, formData, files) {
        this._finished(files, 'locally resolved, refer to "contents" property');
      };
    },

    /**
     * Rather than letting Dropzone send it's own XHR request we'll take over that
     * responsibility here to get around CORS issues when needed. This is activated by
     * setting the components "useAjax" property to true.
     */
    _ajaxSubmitRequest: function _ajaxSubmitRequest() {
      this.dropzone.submitRequest = $.proxy(function (xhr, formData, files) {
        var _this2 = this;

        //jshint ignore:line
        this._sendIntercept(files[0]).then(function (data) {
          $.ajax({
            url: _this2.url,
            method: 'POST',
            crossDomain: true,
            data: data,
            success: xhr.onload,
            error: xhr.onerror
          });
        });
      }, this);
    }

  });
});