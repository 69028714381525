define("ember-leaflet/templates/leaflet-map", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "modules/ember-leaflet/templates/leaflet-map.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "yield", [["subexpr", "hash", [], ["tile", ["subexpr", "component", ["tile-layer"], ["containerLayer", ["subexpr", "@mut", [["get", "this", ["loc", [null, [2, 48], [2, 52]]]]], [], []]], ["loc", [null, [2, 9], [2, 53]]]], "wmsTile", ["subexpr", "component", ["wms-tile-layer"], ["containerLayer", ["subexpr", "@mut", [["get", "this", ["loc", [null, [3, 55], [3, 59]]]]], [], []]], ["loc", [null, [3, 12], [3, 60]]]], "marker", ["subexpr", "component", ["marker-layer"], ["containerLayer", ["subexpr", "@mut", [["get", "this", ["loc", [null, [4, 52], [4, 56]]]]], [], []]], ["loc", [null, [4, 11], [4, 57]]]], "circle", ["subexpr", "component", ["circle-layer"], ["containerLayer", ["subexpr", "@mut", [["get", "this", ["loc", [null, [5, 52], [5, 56]]]]], [], []]], ["loc", [null, [5, 11], [5, 57]]]], "circle-marker", ["subexpr", "component", ["circle-layer"], ["containerLayer", ["subexpr", "@mut", [["get", "this", ["loc", [null, [6, 59], [6, 63]]]]], [], []]], ["loc", [null, [6, 18], [6, 64]]]], "image", ["subexpr", "component", ["image-layer"], ["containerLayer", ["subexpr", "@mut", [["get", "this", ["loc", [null, [7, 50], [7, 54]]]]], [], []]], ["loc", [null, [7, 10], [7, 55]]]], "polyline", ["subexpr", "component", ["polyline-layer"], ["containerLayer", ["subexpr", "@mut", [["get", "this", ["loc", [null, [8, 56], [8, 60]]]]], [], []]], ["loc", [null, [8, 13], [8, 61]]]], "polygon", ["subexpr", "component", ["polygon-layer"], ["containerLayer", ["subexpr", "@mut", [["get", "this", ["loc", [null, [9, 54], [9, 58]]]]], [], []]], ["loc", [null, [9, 12], [9, 59]]]], "geoJSON", ["subexpr", "component", ["geojson-layer"], ["containerLayer", ["subexpr", "@mut", [["get", "this", ["loc", [null, [10, 54], [10, 58]]]]], [], []]], ["loc", [null, [10, 12], [10, 59]]]]], ["loc", [null, [1, 8], [11, 3]]]]], [], ["loc", [null, [1, 0], [11, 5]]]]],
      locals: [],
      templates: []
    };
  })());
});