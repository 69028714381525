define('ember-validators/index', ['exports', 'ember', 'ember-require-module', 'ember-validators/-private/validators-cache'], function (exports, _ember, _emberRequireModule, _emberValidatorsPrivateValidatorsCache) {
  'use strict';

  exports.validate = validate;

  /**
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  var assert = _ember['default'].assert;
  var isPresent = _ember['default'].isPresent;

  function validate(type) {
    var validator = undefined;

    if (_emberValidatorsPrivateValidatorsCache['default'][type]) {
      validator = _emberValidatorsPrivateValidatorsCache['default'][type];
    } else {
      validator = (0, _emberRequireModule['default'])('ember-validators/' + type);
      assert('Validator not found of type: ' + type + '.', isPresent(validator));
      _emberValidatorsPrivateValidatorsCache['default'][type] = validator;
    }

    for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return validator.apply(undefined, args);
  }
});