define('ember-cp-validations/validations/factory', ['exports', 'ember', 'ember-getowner-polyfill', 'ember-cp-validations/utils/flatten', 'ember-cp-validations/utils/assign', 'ember-cp-validations/-private/result', 'ember-cp-validations/validations/result-collection', 'ember-cp-validations/validators/base', 'ember-cp-validations/utils/cycle-breaker', 'ember-cp-validations/utils/should-call-super', 'ember-cp-validations/utils/utils'], function (exports, _ember, _emberGetownerPolyfill, _emberCpValidationsUtilsFlatten, _emberCpValidationsUtilsAssign, _emberCpValidationsPrivateResult, _emberCpValidationsValidationsResultCollection, _emberCpValidationsValidatorsBase, _emberCpValidationsUtilsCycleBreaker, _emberCpValidationsUtilsShouldCallSuper, _emberCpValidationsUtilsUtils) {
  'use strict';

  exports['default'] = buildValidations;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /**
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;
  var RSVP = _ember['default'].RSVP;
  var isNone = _ember['default'].isNone;
  var guidFor = _ember['default'].guidFor;
  var isEmpty = _ember['default'].isEmpty;
  var isArray = _ember['default'].isArray;
  var computed = _ember['default'].computed;
  var makeArray = _ember['default'].makeArray;
  var getWithDefault = _ember['default'].getWithDefault;
  var emberArray = _ember['default'].A;

  var merge = _ember['default'].assign || _ember['default'].merge;

  var Promise = RSVP.Promise;

  /**
   * ## Running Manual Validations
   *
   * Although validations are lazily computed, there are times where we might want to force all or
   * specific validations to happen. For this reason we have exposed three methods:
   *
   * - {{#crossLink "Factory/validateSync:method"}}{{/crossLink}}: Should only be used if all validations are synchronous. It will throw an error if any of the validations are asynchronous
   * - {{#crossLink "Factory/validate:method"}}{{/crossLink}}: Will always return a promise and should be used if asynchronous validations are present
   * - {{#crossLink "Factory/validateAttribute:method"}}{{/crossLink}}: A functional approach to validating an attribute without changing its state
   *
   * @module Validations
   * @main Validations
   */

  /**
   * All validations can be accessed via the `validations` object created on your model/object.
   * Each attribute also has its own validation which has the same properties.
   * An attribute validation can be accessed via `validations.attrs.<ATTRIBUTE>` which will return a {{#crossLink "ResultCollection"}}{{/crossLink}}.
   *
   * ### Global Validations
   *
   * Global validations exist on the `validations` object that resides on the object that is being validated.
   * To see all possible properties, please checkout the docs for {{#crossLink "ResultCollection"}}{{/crossLink}}.
   *
   * ```js
   * model.get('validations.isValid');
   * model.get('validations.errors');
   * model.get('validations.messages');
   * // etc...
   * ```
   *
   * ### Attribute Validations
   *
   * The `validations` object also contains an `attrs` object which holds a {{#crossLink "ResultCollection"}}{{/crossLink}}
   * for each attribute specified in your validation rules.
   *
   * ```js
   * model.get('validations.attrs.username.isValid');
   * model.get('validations.attrs.password.errors');
   * model.get('validations.attrs.email.messages');
   * // etc...
   * ```
   * @module Validations
   * @submodule Accessing Validations
   */

  /**
   * @module Validations
   * @class Factory
   */

  /**
   * Top level method that will ultimately return a mixin with all CP validations
   *
   * @method  buildValidations
   * @param  {Object} validations  Validation rules
   * @return {Ember.Mixin}
   */
  function buildValidations() {
    var validations = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var globalOptions = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    normalizeOptions(validations, globalOptions);

    var Validations = undefined,
        validationMixinCount = undefined;

    var ValidationsMixin = _ember['default'].Mixin.create({
      init: function init() {
        this._super.apply(this, arguments);

        // Count number of mixins to bypass super check if there is more than 1
        this.__validationsMixinCount__ = this.__validationsMixinCount__ || 0;
        validationMixinCount = ++this.__validationsMixinCount__;
      },
      __validationsClass__: computed(function () {
        if (!Validations) {
          var inheritedClass = undefined;

          if ((0, _emberCpValidationsUtilsShouldCallSuper['default'])(this, '__validationsClass__') || validationMixinCount > 1) {
            inheritedClass = this._super();
          }

          Validations = createValidationsClass(inheritedClass, validations, this);
        }
        return Validations;
      }).readOnly(),
      validations: computed(function () {
        return this.get('__validationsClass__').create({ model: this });
      }).readOnly(),
      validate: function validate() {
        var _get;

        return (_get = get(this, 'validations')).validate.apply(_get, arguments);
      },
      validateSync: function validateSync() {
        var _get2;

        return (_get2 = get(this, 'validations')).validateSync.apply(_get2, arguments);
      },
      validateAttribute: function validateAttribute() {
        var _get3;

        return (_get3 = get(this, 'validations')).validateAttribute.apply(_get3, arguments);
      },
      destroy: function destroy() {
        this._super.apply(this, arguments);
        get(this, 'validations').destroy();
      }
    });

    // Label mixin under a named scope for Ember Inspector
    ValidationsMixin[_ember['default'].NAME_KEY] = 'Validations';

    return ValidationsMixin;
  }

  /**
   * Validation rules can be created with default and global options
   * {
   *   description: 'Username',
   *   validators: [...]
   * }
   *
   * This method generate the default options pojo, applies it to each validation rule, and flattens the object
   *
   * @method normalizeOptions
   * @private
   * @param  {Object} validations
   * @return
   */
  function normalizeOptions() {
    var validations = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var globalOptions = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var validatableAttrs = Object.keys(validations);

    validatableAttrs.forEach(function (attribute) {
      var rules = validations[attribute];

      if (rules && typeof rules === 'object' && isArray(rules.validators)) {
        (function () {
          var options = Object.keys(rules).reduce(function (o, k) {
            if (k !== 'validators') {
              o[k] = rules[k];
            }
            return o;
          }, {});

          var validators = rules.validators;

          validators.forEach(function (v) {
            v.defaultOptions = options;
          });
          validations[attribute] = validators;
        })();
      }
      validations[attribute] = makeArray(validations[attribute]);
      validations[attribute].forEach(function (v) {
        v.globalOptions = globalOptions;
      });
    });
  }

  /**
   * Creates the validations class that will become `model.validations`.
   *   - Setup parent validation inheritance
   *   - Normalize nested keys (i.e. 'details.dob') into objects (i.e { details: { dob: validator() }})
   *   - Merge normalized validations with parent
   *   - Create global CPs (i.e. 'isValid', 'messages', etc...)
   *
   * @method createValidationsClass
   * @private
   * @param  {Object} inheritedValidationsClass
   * @param  {Object} validations
   * @param  {Object} model
   * @return {Ember.Object}
   */
  function createValidationsClass(inheritedValidationsClass, validations, model) {
    var validationRules = {};
    var validatableAttributes = Object.keys(validations);

    // Setup validation inheritance
    if (inheritedValidationsClass && inheritedValidationsClass.__isCPValidationsClass__) {
      var inheritedValidations = inheritedValidationsClass.create();

      validationRules = merge(validationRules, inheritedValidations.get('_validationRules'));
      validatableAttributes = emberArray(inheritedValidations.get('validatableAttributes').concat(validatableAttributes)).uniq();
    }

    // Normalize nested keys into actual objects and merge them with parent object
    Object.keys(validations).reduce(function (obj, key) {
      (0, _emberCpValidationsUtilsAssign['default'])(obj, key, validations[key]);
      return obj;
    }, validationRules);

    // Create the mixin that holds all the top level validation props (isValid, messages, etc)
    var TopLevelProps = createTopLevelPropsMixin(validatableAttributes);

    // Create the `attrs` class which will add the current model reference once instantiated
    var AttrsClass = createAttrsClass(validatableAttributes, validationRules, model);

    // Create `validations` class
    var ValidationsClass = _ember['default'].Object.extend(TopLevelProps, {
      model: null,
      attrs: null,
      isValidations: true,

      validatableAttributes: computed(function () {
        return validatableAttributes;
      }).readOnly(),

      // Caches
      _validators: null,
      _debouncedValidations: null,

      // Private
      _validationRules: computed(function () {
        return validationRules;
      }).readOnly(),

      validate: validate,
      validateSync: validateSync,
      validateAttribute: validateAttribute,

      init: function init() {
        this._super.apply(this, arguments);
        this.setProperties({
          attrs: AttrsClass.create({
            _model: this.get('model')
          }),
          _validators: {},
          _debouncedValidations: {}
        });
      },

      destroy: function destroy() {
        this._super.apply(this, arguments);
        var validatableAttrs = get(this, 'validatableAttributes');
        var debouncedValidations = get(this, '_debouncedValidations');

        // Initiate attrs destroy to cleanup any remaining model references
        this.get('attrs').destroy();

        // Cancel all debounced timers
        validatableAttrs.forEach(function (attr) {
          var attrCache = get(debouncedValidations, attr);

          if (!isNone(attrCache)) {
            // Itterate over each attribute and cancel all of its debounced validations
            Object.keys(attrCache).forEach(function (v) {
              return run.cancel(attrCache[v]);
            });
          }
        });
      }
    });

    ValidationsClass.reopenClass({
      __isCPValidationsClass__: true
    });

    return ValidationsClass;
  }

  /**
   * Creates the `attrs` class which holds all the CP logic
   *
   * ```javascript
   * model.get('validations.attrs.username');
   * model.get('validations.attrs.nested.object.attribute');
   * ```
   *
   * @method createAttrsClass
   * @private
   * @param  {Object} validatableAttributes
   * @param  {Object} validationRules
   * @param  {Object} model
   * @return {Ember.Object}
   */
  function createAttrsClass(validatableAttributes, validationRules, model) {
    var nestedClasses = {};
    var rootPath = 'root';

    var AttrsClass = _ember['default'].Object.extend({
      __path__: rootPath,

      init: function init() {
        var _this = this;

        this._super.apply(this, arguments);

        var _model = this.get('_model');
        var path = this.get('__path__');

        /*
          Instantiate the nested attrs classes for the current path
         */
        Object.keys(nestedClasses[path] || []).forEach(function (key) {
          set(_this, key, nestedClasses[path][key].create({
            _model: _model
          }));
        });
      },

      destroy: function destroy() {
        var _this2 = this;

        this._super.apply(this, arguments);

        var path = this.get('__path__');

        /*
          Remove the model reference from each nested class and destroy it
         */
        Object.keys(nestedClasses[path] || []).forEach(function (key) {
          var o = get(_this2, key);
          o.set('_model', null);
          o.destroy();
        });
      }
    });

    /*
      Insert CPs + Create nested classes
     */
    validatableAttributes.forEach(function (attribute) {
      var path = attribute.split('.');
      var attr = path.pop();
      var currPath = [rootPath];
      var currClass = AttrsClass;
      var cpHash = {};

      // Iterate over the path and create the necessary nested classes along the way
      for (var i = 0; i < path.length; i++) {
        var key = path[i];
        var currPathStr = currPath.join('.');
        var _nestedClasses = undefined;

        nestedClasses[currPathStr] = nestedClasses[currPathStr] || {};
        _nestedClasses = nestedClasses[currPathStr];

        currPath.push(key);

        if (!_nestedClasses[key]) {
          _nestedClasses[key] = AttrsClass.extend({
            __path__: currPath.join('.')
          });
        }

        currClass = _nestedClasses[key];
      }

      // Add the final attr's CP to the class
      cpHash[attr] = createCPValidationFor(attribute, model, get(validationRules, attribute));
      currClass.reopen(cpHash);
    });

    return AttrsClass;
  }

  /**
   * CP generator for the given attribute
   *
   * @method createCPValidationFor
   * @private
   * @param  {String} attribute
   * @param  {Object} model         Since the CPs are created once per class on the first initialization,
   *                                this is the first model that was instantiated
   * @param  {Array} validations
   * @return {Ember.ComputedProperty} A computed property which is a ResultCollection
   */
  function createCPValidationFor(attribute, model, validations) {
    var isVolatile = hasOption(validations, 'volatile', true);
    var dependentKeys = isVolatile ? [] : getCPDependentKeysFor(attribute, model, validations);

    var cp = computed.apply(undefined, _toConsumableArray(dependentKeys).concat([(0, _emberCpValidationsUtilsCycleBreaker['default'])(function () {
      var model = get(this, '_model');
      var validators = !isNone(model) ? getValidatorsFor(attribute, model) : [];

      var validationResults = generateValidationResultsFor(attribute, model, validators, function (validator, options) {
        return validator.validate(validator.getValue(), options, model, attribute);
      });

      return _emberCpValidationsValidationsResultCollection['default'].create({
        attribute: attribute,
        content: validationResults
      });
    })])).readOnly();

    if (isVolatile) {
      cp = cp.volatile();
    }

    return cp;
  }

  /**
   * Check if a collection of validations have an option
   * equal to the given value
   *
   * @method hasOption
   * @private
   * @param {Array} validations
   * @param {String} option
   * @param {Boolean} [value=true]
   * @returns {Boolean}
   */
  function hasOption(validations, option) {
    var value = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

    for (var i = 0; i < validations.length; i++) {
      var _validations$i = validations[i];
      var options = _validations$i.options;
      var _validations$i$defaultOptions = _validations$i.defaultOptions;
      var defaultOptions = _validations$i$defaultOptions === undefined ? {} : _validations$i$defaultOptions;
      var _validations$i$globalOptions = _validations$i.globalOptions;
      var globalOptions = _validations$i$globalOptions === undefined ? {} : _validations$i$globalOptions;

      var mergedOptions = (0, _emberCpValidationsUtilsUtils.mergeOptions)(options, defaultOptions, globalOptions);

      if (mergedOptions[option] === value) {
        return true;
      }
    }

    return false;
  }

  /**
   * Generates the validation results for a given attribute and validators. If a
   * given validator should be validated, it calls upon the validate callback to retrieve
   * the result.
   *
   * @method generateValidationResultsFor
   * @private
   * @param  {String} attribute
   * @param  {Object} model
   * @param  {Array} validators
   * @param  {Function} validate
   * @param  {Object} opts
   *                    - disableDebounceCache {Boolean}
   * @return {Array}
   */
  function generateValidationResultsFor(attribute, model, validators, validate) {
    var opts = arguments.length <= 4 || arguments[4] === undefined ? {} : arguments[4];

    var isModelValidatable = (0, _emberCpValidationsUtilsUtils.isValidatable)(model);
    var isInvalid = false;
    var value = undefined,
        result = undefined;

    return validators.map(function (validator) {
      var _options = get(validator, 'options');
      var options = _options.copy();
      var isWarning = getWithDefault(options, 'isWarning', false);
      var disabled = getWithDefault(options, 'disabled', false);
      var debounce = getWithDefault(options, 'debounce', 0);
      var lazy = getWithDefault(options, 'lazy', true);

      if (disabled || lazy && isInvalid || !isModelValidatable) {
        value = true;
      } else if (debounce > 0) {
        (function () {
          var cache = getDebouncedValidationsCacheFor(attribute, model);

          // Return a promise and pass the resolve method to the debounce handler
          value = new Promise(function (resolve) {
            var t = run.debounce(validator, resolve, debounce, false);

            if (!opts.disableDebounceCache) {
              cache[guidFor(validator)] = t;
            }
          }).then(function () {
            return validate(validator, _options.copy());
          });
        })();
      } else {
        value = validate(validator, options);
      }

      result = validationReturnValueHandler(attribute, value, model, validator);

      /*
        If the current result is invalid, the rest of the validations do not need to be
        triggered (if lazy) since the attribute is already in an invalid state.
       */
      if (!isInvalid && !isWarning && get(result, 'isInvalid')) {
        isInvalid = true;
      }

      return result;
    });
  }

  /**
   * Create a mixin that will have all the top level CPs under the validations object.
   * These are computed collections on different properties of each attribute validations CP
   *
   * @method createTopLevelPropsMixin
   * @private
   * @param  {Object} validations
   */
  function createTopLevelPropsMixin(validatableAttrs) {
    // Expose the following properties as public APIs via readOnly aliases
    var aliases = ['isWarning', 'isValid', 'isValidating', 'isDirty', 'isAsync', 'isNotValidating', 'isInvalid', 'isTruelyValid', 'messages', 'message', 'warningMessages', 'warningMessage', 'warnings', 'warning', 'errors', 'error', '_promise'];

    var topLevelProps = aliases.reduce(function (props, alias) {
      props[alias] = computed.readOnly('__attrsResultCollection__.' + alias);
      return props;
    }, {});

    return _ember['default'].Mixin.create(topLevelProps, {
      /*
        Dedupe logic by creating a top level ResultCollection for all attr's ResultCollections
       */
      __attrsResultCollection__: computed.apply(undefined, _toConsumableArray(validatableAttrs.map(function (attr) {
        return 'attrs.' + attr;
      })).concat([function () {
        var _this3 = this;

        return _emberCpValidationsValidationsResultCollection['default'].create({
          content: validatableAttrs.map(function (attr) {
            return get(_this3, 'attrs.' + attr);
          })
        });
      }])).readOnly()
    });
  }

  /**
   * CP dependency generator for a give attribute depending on its relationships
   *
   * @method getCPDependentKeysFor
   * @private
   * @param  {String} attribute
   * @param  {Object} model         Since the CPs are created once per class on the first initialization,
   *                                this is the first model that was instantiated
   * @param  {Array} validations
   * @return {Array} Unique list of dependencies
   */
  function getCPDependentKeysFor(attribute, model, validations) {
    var owner = (0, _emberGetownerPolyfill['default'])(model);

    var dependentKeys = validations.map(function (validation) {
      var options = validation.options;

      var type = validation._type;
      var Validator = type === 'function' ? _emberCpValidationsValidatorsBase['default'] : lookupValidator(owner, type);
      var baseDependents = _emberCpValidationsValidatorsBase['default'].getDependentsFor(attribute, options) || [];
      var dependents = Validator.getDependentsFor(attribute, options) || [];

      var specifiedDependents = [].concat(getWithDefault(options, 'dependentKeys', []), getWithDefault(validation, 'defaultOptions.dependentKeys', []), getWithDefault(validation, 'globalOptions.dependentKeys', []));

      // Extract dependentKeys from option CPs
      var cpDependents = [].concat(extractOptionsDependentKeys(options), extractOptionsDependentKeys(get(validation, 'defaultOptions')), extractOptionsDependentKeys(get(validation, 'globalOptions')));

      return baseDependents.concat(dependents, cpDependents, specifiedDependents);
    });

    dependentKeys = (0, _emberCpValidationsUtilsFlatten['default'])(dependentKeys);

    dependentKeys.push('model.' + attribute);

    if ((0, _emberCpValidationsUtilsUtils.isDsModel)(model)) {
      dependentKeys.push('model.isDeleted');
    }

    dependentKeys = dependentKeys.map(function (d) {
      return '' + (d.split('.')[0] === 'model' ? '_' : '') + d;
    });

    return emberArray(dependentKeys).uniq();
  }

  /**
   * Extract all dependentKeys from any property that is a CP
   *
   * @method extractOptionsDependentKeys
   * @private
   * @param  {Object} options
   * @return {Array}  dependentKeys
   */
  function extractOptionsDependentKeys(options) {
    if (options && typeof options === 'object') {
      return Object.keys(options).reduce(function (arr, key) {
        var option = options[key];

        if (option && typeof option === 'object' && option.isDescriptor) {
          return arr.concat(option._dependentKeys || []);
        }

        return arr;
      }, []);
    }

    return [];
  }

  /**
   * A handler used to create ValidationResult object from values returned from a validator
   *
   * @method validationReturnValueHandler
   * @private
   * @param  {String} attribute
   * @param  {Mixed} value
   * @param  {Object} model
   * @return {ValidationResult}
   */
  function validationReturnValueHandler(attribute, value, model, validator) {
    var result = undefined;
    var commonProps = {
      model: model,
      attribute: attribute,
      _validator: validator
    };

    if ((0, _emberCpValidationsUtilsUtils.isPromise)(value)) {
      result = _emberCpValidationsPrivateResult['default'].create(commonProps, {
        _promise: Promise.resolve(value)
      });
    } else {
      result = _emberCpValidationsPrivateResult['default'].create(commonProps);
      result.update(value);
    }

    return result;
  }

  /**
   * Get validators for the give attribute. If they are not in the cache, then create them.
   *
   * @method getValidatorsFor
   * @private
   * @param  {String} attribute
   * @param  {Object} model
   * @return {Array}
   */
  function getValidatorsFor(attribute, model) {
    var validators = get(model, 'validations._validators.' + attribute);

    if (!isNone(validators)) {
      return validators;
    }

    return createValidatorsFor(attribute, model);
  }

  /**
   * Get debounced validation cache for the given attribute. If it doesn't exist, create a new one.
   *
   * @method getValidatorCacheFor
   * @private
   * @param  {String} attribute
   * @param  {Object} model
   * @return {Map}
   */
  function getDebouncedValidationsCacheFor(attribute, model) {
    var debouncedValidations = get(model, 'validations._debouncedValidations');

    if (isNone(get(debouncedValidations, attribute))) {
      (0, _emberCpValidationsUtilsAssign['default'])(debouncedValidations, attribute, {});
    }

    return get(debouncedValidations, attribute);
  }

  /**
   * Create validators for the give attribute and store them in a cache
   *
   * @method createValidatorsFor
   * @private
   * @param  {String} attribute
   * @param  {Object} model
   * @return {Array}
   */
  function createValidatorsFor(attribute, model) {
    var validations = get(model, 'validations');
    var validationRules = makeArray(get(validations, '_validationRules.' + attribute));
    var validatorCache = get(validations, '_validators');
    var owner = (0, _emberGetownerPolyfill['default'])(model);
    var validators = [];
    var validator = undefined;

    // We must have an owner to be able to lookup our validators
    if (isNone(owner)) {
      throw new TypeError('[ember-cp-validations] ' + model.toString() + ' is missing a container or owner.');
    }

    validationRules.forEach(function (v) {
      v.attribute = attribute;
      v.model = model;

      // If validate function exists, that means validator was created with a function so use the base class
      if (v._type === 'function') {
        validator = _emberCpValidationsValidatorsBase['default'].create(owner.ownerInjection(), v);
      } else {
        validator = lookupValidator(owner, v._type).create(v);
      }
      validators.push(validator);
    });

    // Add validators to model instance cache
    (0, _emberCpValidationsUtilsAssign['default'])(validatorCache, attribute, validators);

    return validators;
  }

  /**
   * Lookup a validators of a specific type on the owner
   *
   * @method lookupValidator
   * @throws {Error} Validator not found
   * @private
   * @param  {Ember.Owner} owner
   * @param  {String} type
   * @return {Class} Validator class or undefined if not found
   */
  function lookupValidator(owner, type) {
    var validatorClass = owner._lookupFactory('validator:' + type);

    if (isNone(validatorClass)) {
      throw new Error('[ember-cp-validations] Validator not found of type: ' + type + '.');
    }
    return validatorClass;
  }

  /**
   * ### Options
   * - `on` (**Array**): Only validate the given attributes. If empty, will validate over all validatable attribute
   * - `excludes` (**Array**): Exclude validation on the given attributes
   *
   * ```javascript
   * model.validate({ on: ['username', 'email'] }).then(({ m, validations }) => {
   *   validations.get('isValid'); // true or false
   *   validations.get('isValidating'); // false
   *
   *   let usernameValidations = m.get('validations.attrs.username');
   *   usernameValidations.get('isValid') // true or false
   * });
   * ```
   *
   * @method validate
   * @param  {Object}  options
   * @param  {Boolean} async      If `false`, will get all validations and will error if an async validations is found.
   *                              If `true`, will get all validations and wrap them in a promise hash
   * @return {Promise or Object}  Promise if async is true, object if async is false
   */
  function validate() {
    var _this4 = this;

    var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var async = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

    var model = get(this, 'model');
    var whiteList = makeArray(options.on);
    var blackList = makeArray(options.excludes);

    var validationResults = get(this, 'validatableAttributes').reduce(function (v, name) {
      if (!isEmpty(blackList) && blackList.indexOf(name) !== -1) {
        return v;
      }

      if (isEmpty(whiteList) || whiteList.indexOf(name) !== -1) {
        var validationResult = get(_this4, 'attrs.' + name);

        // If an async validation is found, throw an error
        if (!async && get(validationResult, 'isAsync')) {
          throw new Error('[ember-cp-validations] Synchronous validation failed due to ' + name + ' being an async validation.');
        }

        v.push(validationResult);
      }
      return v;
    }, []);

    var resultCollection = _emberCpValidationsValidationsResultCollection['default'].create({
      content: validationResults
    });

    var resultObject = {
      model: model,
      validations: resultCollection
    };

    if (async) {
      if (get(resultCollection, 'isAsync')) {
        return RSVP.allSettled(makeArray(get(resultCollection, '_promise'))).then(function () {
          return resultObject;
        });
      }
      return Promise.resolve(resultObject);
    }

    return resultObject;
  }

  /**
   * A functional approach to check if a given attribute on a model is valid independently of the
   * model attribute's validations. This method will always return a promise which will then resolve
   * to a {{#crossLink "ResultCollection"}}{{/crossLink}}.
   *
   * ```javascript
   * model.validateAttribute('username', 'offirgolan').then(({ m, validations }) => {
   *   validations.get('isValid'); // true or false
   *   validations.get('isValidating'); // false
   * });
   * ```
   *
   * @method validateAttribute
   * @param  {String}   attribute
   * @param  {Mixed}  value
   * @return {Promise}
   * @async
   */
  function validateAttribute(attribute, value) {
    var model = get(this, 'model');
    var validators = !isNone(model) ? getValidatorsFor(attribute, model) : [];

    var validationResults = generateValidationResultsFor(attribute, model, validators, function (validator, options) {
      return validator.validate(value, options, model, attribute);
    }, {
      disableDebounceCache: true
    });

    var validations = _emberCpValidationsValidationsResultCollection['default'].create({
      attribute: attribute,
      content: (0, _emberCpValidationsUtilsFlatten['default'])(validationResults)
    });

    var result = { model: model, validations: validations };

    return Promise.resolve(get(validations, 'isAsync') ? get(validations, '_promise').then(function () {
      return result;
    }) : result);
  }

  /**
   * ### Options
   * - `on` (**Array**): Only validate the given attributes. If empty, will validate over all validatable attribute
   * - `excludes` (**Array**): Exclude validation on the given attributes
   *
   * ```javascript
   * let { m, validations } = model.validateSync();
   * validations.get('isValid') // true or false
   * ```
   *
   * @method validateSync
   * @param  {Object}  options
   * @return {Object}
   */
  function validateSync(options) {
    return this.validate(options, false);
  }
});