define('ember-leaflet/mixins/container', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var A = _ember['default'].A;

  exports['default'] = _ember['default'].Mixin.create({
    _childLayers: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('_childLayers', new A());
    },

    registerChild: function registerChild(childLayer) {
      this._childLayers.addObject(childLayer);

      //If container already setup setup child immediatly
      if (this._layer) {
        childLayer.layerSetup();
      }
    },

    unregisterChild: function unregisterChild(childLayer) {
      this._childLayers.removeObject(childLayer);

      //If container already setup teardown child immediatly
      if (this._layer) {
        childLayer.layerTeardown();
      }
    }
  });
});