define('ember-leaflet-google-tile-layer/components/google-tile-layer', ['exports', 'ember-leaflet/components/base-layer'], function (exports, _emberLeafletComponentsBaseLayer) {
  'use strict';

  var _bind = Function.prototype.bind;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports['default'] = _emberLeafletComponentsBaseLayer['default'].extend({

    leafletRequiredOptions: ['type' // Possible types: SATELLITE, ROADMAP, HYBRID, TERRAIN
    ],

    leafletOptions: ['mapOptions', 'attribution', 'opacity', 'maxZoom'],

    leafletEvents: ['load'],

    leafletProperties: ['opacity'],

    createLayer: function createLayer() {
      return new (_bind.apply(this.L.Google, [null].concat(_toConsumableArray(this.get('requiredOptions')), [this.get('options')])))();
    }
  });
});