define('ui-dropzone/components/drop-zone', ['exports', 'ember', 'ui-dropzone/mixins/xhr-intercept', 'ui-dropzone/templates/components/drop-zone'], function (exports, _ember, _uiDropzoneMixinsXhrIntercept, _uiDropzoneTemplatesComponentsDropZone) {
  'use strict';

  var keys = Object.keys;
  var create = Object.create;
  // jshint ignore:line
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var on = _ember['default'].on;
  var typeOf = _ember['default'].typeOf;
  var debug = _ember['default'].debug;
  var isPresent = _ember['default'].isPresent;
  // jshint ignore:line
  var defineProperty = _ember['default'].defineProperty;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  var merge = _ember['default'].merge;
  // jshint ignore:line
  var a = _ember['default'].A; // jshint ignore:line
  var pascalize = function pascalize(thingy) {
    return thingy ? _ember['default'].String.capitalize(_ember['default'].String.camelize(thingy)) : thingy;
  };
  var camelizedTranslation = function camelizedTranslation(name) {
    // For consistency sake, allows all parameters to be camelcase in Ember
    var camels = ['dragStart', 'dragEnd', 'dragEnter', 'dragOver', 'dragLeave', 'addedFile', 'removedFile', 'uploadProgress', 'maxFilesReached', 'maxFileSize', 'maxFilesExceeded', 'processingMultiple', 'sendingMultiple', 'successMultiple', 'completeMultiple', 'canceledMultiple', 'totalUploadProgress', 'queueComplete'];
    var translated = {};
    camels.forEach(function (item) {
      translated[item.toLowerCase()] = item;
    });

    return a(keys(translated)).contains(name) ? translated[name] : name;
  };

  // Configuration Objects
  // > http://www.dropzonejs.com/#configuration
  var configurationOptions = ['url', 'withCredentials', 'method', 'parallelUploads', 'maxFilesize', 'filesizeBase', 'paramName', 'uploadMultiple', 'uploadMultiple', 'headers', 'addRemoveLinks', 'previewsContainer', 'clickable', 'createImageThumbnails', 'maxThumbnailFilesize', 'thumbnailWidth', 'thumbnailHeight', 'maxFiles', 'acceptedFiles', 'autoProcessQueue', 'forceFallback', 'previewTemplate'];
  var optionalCallbacks = ['initialise', 'accept', 'resize', 'fallback'];
  // Dropzone translations
  // aka, text/html that shows up in various states
  var translations = ['dictDefaultMessage', 'dictFallbackMessage', 'dictFallbackText', 'dictInvalidFileType', 'dictFileTooBig', 'dictResponseError', 'dictCancelUpload', 'dictCancelUploadConfirmation', 'dictRemoveFile', 'dictMaxFilesExceeded'];
  // All Configuration
  var allConfiguration = [].concat(configurationOptions, optionalCallbacks, translations);
  // Events
  // All of these receive the event as first parameter:
  var dragEvents = ['drop', 'dragstart', 'dragend', 'dragenter', 'dragover', 'dragleave'];
  // All of these receive the file as first parameter:
  var fileEvents = ['addedfile', 'removedfile', 'thumbnail', 'error', 'processing', 'uploadprogress', 'sending', 'success', 'complete', 'canceled', 'maxfilesreached', 'maxfilesexceeded'];
  // All of these receive a list of files as first parameter and are only
  // called if the uploadMultiple option is true:
  var multiFileEvents = ['processingmultiple', 'sendingmultiple', 'successmultiple', 'completemultiple', 'canceledmultiple'];
  // Special Events
  // note: note sure yet what "special" means
  var specialEvents = ['totaluploadprogress', 'reset', 'queuecomplete'];
  // maps template names to the API
  var templateLookup = {
    preview: 'previewTemplate',
    "default-message": 'dictDefaultMessage',
    "fallback-message": 'dictFallbackMessage',
    "invalid-file-type": 'dictInvalidFileType',
    "file-too-big": 'dictFileTooBig'
  };exports['default'] = _ember['default'].Component.extend(_uiDropzoneMixinsXhrIntercept['default'], {
    layout: _uiDropzoneTemplatesComponentsDropZone['default'],
    classNames: ['dropzone'],
    propertyNamesBindings: ['ariaRole'],
    ariaRole: 'input',

    // Default values
    url: '#', // note: this can be a function too
    autoProcessQueue: true,

    // Download file to browser but keep local to browser
    keepLocal: false,
    _keepLocal: on('init', function () {
      var keepLocal = this.get('keepLocal');
      if (keepLocal) {
        this.accept = this.localAcceptHandler;
      }
    }),
    // Switch away from built-in way of sending
    // and use AJAX instead
    useAjax: false,

    // Config counters
    handlers: computed(function () {
      return a();
    }),
    overwriteHandlers: computed(function () {
      return a();
    }),
    customTemplates: computed(function () {
      return a();
    }),
    localPayloads: computed(function () {
      return a();
    }),

    getDropzoneOptions: function getDropzoneOptions() {
      var _this = this;

      var dropzoneOptions = {};
      dropzoneOptions.init = null;
      // add configuration
      a(allConfiguration).forEach(function (option) {
        var prop = _this.get(option);
        var namedOption = option === 'intialize' ? 'init' : option; // avoids conflict with Ember's 'init()'
        if (isPresent(prop)) {
          dropzoneOptions[namedOption] = prop;
        }
      });
      // Event Overrides
      // note: usually better to use the "additive" listening events rather than overriding
      var allEvents = [].concat(dragEvents, fileEvents, multiFileEvents, specialEvents, optionalCallbacks);
      var overwriteHandlers = this.get('overwriteHandlers');
      a(allEvents).forEach(function (event) {
        var prop = _this.get(event) || _this.get(camelizedTranslation(event));
        if (isPresent(prop)) {
          overwriteHandlers.pushObject(event);
          if (typeOf(prop) === 'function') {
            dropzoneOptions[event] = _ember['default'].$.proxy(prop, _this); // allow event to access Ember context
          } else {
              debug('Event "' + event + '" was configured but was not a function, ignoreing.');
            }
        }
      });

      return dropzoneOptions;
    },
    // check for non-caemojiSuccess first but then check camelized with 'on' prefix
    getHandler: function getHandler(event) {
      var emberStandardName = 'on' + pascalize(camelizedTranslation(event));
      var emberEvent = this.get(emberStandardName);
      if (typeOf(emberEvent) === 'function') {
        return emberEvent;
      } else {
        return null;
      }
    },

    insertDropzone: on('didInsertElement', function () {
      var _this2 = this;

      window.Dropzone.autoDiscover = false;
      run.schedule('afterRender', function () {
        var options = _this2.getDropzoneOptions();
        // Create dropzone object
        delete options.init; // TODO: look into why a function was inadvertently making it in here and causing problems
        _this2.$().dropzone(options);
        var dropzone = _this2.$()[0].dropzone;
        // Setup event listeners
        var allEvents = [].concat(dragEvents, fileEvents, multiFileEvents, specialEvents);
        var handlerList = _this2.get('handlers');
        a(allEvents).forEach(function (event) {
          var handler = _this2.getHandler(event);
          if (handler) {
            dropzone.on(event, $.proxy(handler, _this2));
            handlerList.pushObject(event);
          }
        });
        // Save object reference
        _this2.set('dropzone', dropzone);
        // local override
        if (_this2.keepLocal) {
          run.next(function () {
            _this2._stubSubmitRequest();
          });
        }
        // ajax override
        if (_this2.useAjax) {
          run.next(function () {
            _this2._ajaxSubmitRequest();
          });
        }
        _this2.loadPreExistingFiles();
      });
    }),
    loadPreExistingFiles: function loadPreExistingFiles() {
      // TODO: implement

      // if ( this.files && this.files.length > 0 ) {
      //   this.files.map( function( file ) {
      //     let dropfile = {
      //       name: file.get('name'),
      //       type: file.get('type'),
      //       size: file.get('size'),
      //       status: Dropzone.ADDED
      //     };
      //     let thumbnail = file.get('thumbnail');
      //
      //     if ( typeof(thumbnail) === 'string' ) {
      //
      //       dropfile.thumbnail = thumbnail;
      //     }
      //
      //     self.myDropzone.emit('addedfile', dropfile);
      //
      //     if ( typeof(thumbnail) === 'string' ) {
      //
      //       self.myDropzone.emit('thumbnail', dropfile, thumbnail);
      //     }
      //
      //     self.myDropzone.emit('complete', dropfile);
      //     self.myDropzone.files.push(file);
      //   });
      // }
    },
    registerTemplate: function registerTemplate(type, content) {
      // let templates = this.get('customTemplates');
      // templates[type] = content; // this may be redundant but keeping for now

      if (templateLookup[type]) {
        this.set(templateLookup[type], content);
      } else {
        debug('Recieved an unknown template type: "' + type + '"');
      }

      return this.elementId;
    },
    contextualiseRegistration: on('init', function () {
      this.set('_registerTemplate', $.proxy(this.get('registerTemplate'), this));
    })

  });
});